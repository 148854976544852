// import jquery
// browserify will remove the duplicates
import $ from 'jquery';
import jQuery from 'jquery';

import Wallop from 'wallop';

// import everything else we need
import * as Layout from './layout';
import * as Distilleries from './distilleries';
import TrailMap from './trail_map';
import * as Mailchimp from './mailchimp';
import Cookies from 'js-cookie';

// export jquery for others scripts to use
window.$ = $;
window.jQuery = jQuery;

// export cookies for others scripts to use
window.Cookies = Cookies;

function checkCookie(cookieName) {
  if (Cookies.get(cookieName)) {
    return true;
  }
  return false;
}

var popupName = document.querySelectorAll('[data-cookie-popup]');

function showPopupIfCookie(popup) {
  if (!checkCookie(popup)) {
    document.querySelector('[data-cookie-popup="' + popup + '"]').classList.add('popup--open');
  }
  console.log("popup: " + checkCookie(popup));
}

for (var i = 0; i < popupName.length; i++) {
  var name = popupName[i].getAttribute('data-cookie-popup');
  showPopupIfCookie(name);
  console.log("popup: " + name);
}

function setCookie(name, url = null) {
  Cookies.set(name, 'true', { expires: 7 });
  if (url) { 
    window.location.href = url;
  }
}

//
// Set footer padding and any other layout tweaks
Layout.init();


//
// wait for the main elements height to be set
window.addEventListener("layoutReady", function() {
  const map = new TrailMap('trail_map');
  // map.debug_map();
});


//
// Init mailchimp form
Mailchimp.init('#mc-embedded-subscribe-form', '#mce-responses');


;(function () {

  const mq = window.matchMedia("(max-width: 641px)");

  //
  // Show mobile menu
  $('#show_menu').click(function () {
    $(this).next('div').toggleClass('show');
    $('.slo-mini-map').toggle();
  })

  //
  // Show mini map

  $('.mini-map-image').hide();
  $('.slo-dis-layer').hide();
  var mapPos = "0";

  function toggleMap() {
    var pageHeight = $(window).height();
    var imgHeight =$('.mini-map-image').height();
    var footerHeight = $('#site_footer').height();
    var mapPosOpen = (-imgHeight - 30);
    if(!$('#show_mini_map').hasClass('open')) {
      mapPos = mapPosOpen;
      if(mq.matches) {
        var mapPosOpen = (-imgHeight - 30);
        mapPos = mapPosOpen;
      }
    }
    $('.slo-mini-map').animate({ top: mapPos + "px" }, 300);
    $('.mini-map-image').slideToggle(300);
    $('.slo-dis-layer').toggle();
    $('#show_mini_map .arrow').toggleClass('arrow-up');
    $('#show_mini_map .arrow').toggleClass('arrow-down');
    $('#show_mini_map').toggleClass("open");
  }

  $('#show_mini_map').on("click", function(e) {
    e.preventDefault();
    toggleMap();
  });

  $(window).resize(function() {
    $('.slo-mini-map').animate({ top: mapPos + "px" }, 300);
  });

  if(mq.matches) {
    $('.distillery_list--logo_column').hide();
    $('.distillery_list--info_column').hide();
  } else {

    toggleMap();

    //
    // distilleries masonry style layout, only call if over 641px
    Distilleries.init();

  }
  $('.distillery_list--distillery .show').on("click", function(e){
    e.preventDefault();
    $(this).find('.arrow').toggleClass('arrow-up');
    $(this).parent().parent().find('.distillery_list--logo_column, .distillery_list--info_column').slideToggle(300);
  });



  // HOMEPAGE - swap video with unmuted version and play
  var footerHt = $('#site_footer').height();
  var ht = $("#main").height();
  var totalHt = ht - footerHt + "px";
  console.log('footer:' + footerHt + ', ' + $("#video-wrapper").height());
  if($("#video-wrapper").length) {
    $("#video-wrapper").css({
      height: ht
    });
    setInterval(function () {
      footerHt = $('#site_footer').height();
      totalHt = ht - footerHt + "px";
      $("#video-wrapper").css({
        height:totalHt
      });
    }, 300);
  }

  $("#home__playbtn").on("click", function(e){
    e.preventDefault();
    var src = $("#replace-video").attr("src");
    src = src.replace("controls=0", "controls=1");
    src = src.replace("mute=1", "mute=0");
    src = src.replace("loop=1", "loop=0");
    $("#logo-video, #video-overlay, .date_countdown").fadeOut(500, function(){
      $("#video-wrapper").css({
        height: ht
      });
      $("#replace-video").attr("src", src).css({
        width: "100%",
        backgroundColor: "#fff"
      });
    });
  });

  /* set Cookies */
  $('[data-set-cookie]').on("click", function(e){
    e.preventDefault();
    var name = $(this).attr("data-set-cookie");
    var url = $(this).attr("href");
    setCookie(name, url);
  });

})();

//
// Init Wallop slider
var wallopEl = document.querySelector('.Wallop');
if (wallopEl) {
  var wallop = new Wallop(wallopEl);

  var paginationDots = Array.prototype.slice.call(document.querySelectorAll('.Wallop-dot'));

  /*
  Attach click listener on the dots
  */
  paginationDots.forEach(function (dotEl, index) {
    dotEl.addEventListener('click', function() {
      wallop.goTo(index);
    });
  });

  /*
  Listen to wallop change and update classes
  */
  wallop.on('change', function(event) {
    removeClass(document.querySelector('.Wallop-dot--current'), 'Wallop-dot--current');
    addClass(paginationDots[event.detail.currentItemIndex], 'Wallop-dot--current');
  });

  // Helpers
  function addClass(element, className) {
    if (!element) { return; }
    element.className = element.className.replace(/\s+$/gi, '') + ' ' + className;
  }

  function removeClass(element, className) {
    if (!element) { return; }
    element.className = element.className.replace(className, '');
  }
}
